<template>
  <div class="home-hero-featured-search-bar column is-12">
    <nav class="buttons has-text-centered is-centered">
      <form
        class="form column is-6"
        @submit.stop.prevent="submit()"
      >
        <div class="field">
          <p class="control has-icons-left">
            <input
              v-model="searchBar"
              name="searchBar"
              type="text"
              class="ais-SearchBox-input input searchBox"
              :placeholder="placeHolderText"
              autofocus
            >
            <span class="icon has-text-grey is-left is-large">
              <fa icon="search" size="lg" full-width />
            </span>
          </p>
        </div>
      </form>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'HeroDirectoryButtons',
  props: {
    directories: {
      type: Array,
      required: true,
    },
    searchProject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchBar: null,
    };
  },
  computed: {
    placeHolderText() {
      const project = this.directories.find((directory) => directory.slug === this.searchProject);
      return `Search for records in ${project.name}`;
    },
  },
  methods: {
    submit() {
      this.$router.push(`/${this.searchProject}/?q=${this.searchBar}`);
    },
  },
};
</script>
